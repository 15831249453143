<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div id="printGuidance">
              <b-table
                :items="items"
                :fields="fields"
                class="mt-3"
                border="1"
                bordered
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(student_name)="data">
                  <span class="d-block">{{ data.item.student_name }} </span>
                </template>

              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  props: {
    filterProps: Object,
  },
  data() {
    return {
      currentUser: getUser(),
      statusOptions: [
        { text: "Belum terkonfirmasi", value: 0 },
        { text: "Terkonfirmasi", value: 1 },
      ],
      //
      showDetail: `<i class="fas fa-eye-slash px-0"></i>`,
      hideDetail: `<i class="fas fa-eye px-0"></i>`,
      // filter
      filter: {
        student_name: "",
        teacher_id: getUser().role_id == 1 ? getUser().id : "",
        student_id: getUser().role_id == 2 ? getUser().id : "",
        guidance_type_id: 1,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "#",
        },
        {
          key: "student_name",
          label: "Mahasiswa",
        },
        {
          key: "student_registration_number",
          label: "NIM",
        },
        {
          key: "teacher_1_name",
          label: "Pembimbing",
        },
      ],
      items: [],
      // other
      verificationRequest: {
        id: "",
        status_id: "",
        teacher_notes: "",
        consultation_content: "",
      },
      error: {
        teacher_notes: "",
      },
      query: "",
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `start_date=${this.filterProps.start_date}&end_date=${this.filterProps.end_date}&guidance_type_id=${this.filterProps.guidance_type_id}&student_name=${this.filterProps.student_name}&teacher_id=${this.filterProps.teacher_id}&student_id=${this.filterProps.student_id}`;
      let response = await module.paginate(
        "api/student-consultations-no-pagination",
        `?${filterParams}`
      );

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async btnPrintOnClick() {
      await this.$htmlToPaper("printMe");
    },

    gotoEdit(id) {
      // btnActive = "days"
      this.$router.push({
        path: "/academic-guidance/edit/" + id,
        query: this.btnActive,
      });
    },

    showModalStatus(id, status_id, data) {
      if (
        getUser().role_key_name == "teacher" ||
        getUser().role_key_name == "admin_korprodi" ||
        getUser().role_key_name == "admin_kepala_lab"
      ) {
        this.verificationRequest.id = id;
        this.verificationRequest.status_id = status_id;
        this.verificationRequest.consultation_content =
          data.consultation_content;
        this.verificationRequest.teacher_notes = data.teacher_notes;
        this.$bvModal.show("modal-status");
      }
    },

    async verification() {
      this.verificationRequest["_method"] = "put";
      let response = await module.submit(
        this.verificationRequest,
        `api/student-consultations/${this.verificationRequest.id}/verification`
      );
      if (response.state != "error") {
        this.pagination();
        // Success
        Swal.fire({
          title: response.success.title,
          text: "Berhasil mengubah status bimbingan skripsi",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    filterByName() {
      // let timeout = null;

      // clearTimeout(timeout);

      // timeout = setTimeout(() => {
      //     console.log('jalankan pagination')
      //     this.pagination();
      //   }, 1000);
      this.pagination();
    },

    // filter
    resetFilter() {
      // set btn Active
      this.btnActive.days = true;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      this.filter.student_name = "";
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.deleteGuidance(
        "api/student-consultations/" + id,
        "Berhasil menghapus bimbingan akademik"
      );
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1302") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bimbingan Akademik" }]);
    this.pagination();
    this.checkAccess();

    this.$root.$on("pagination", () => {
      console.log("receipt emit");
      this.pagination();
    });

    if (this.$route.query.hasOwnProperty("btnActive")) {
      console.log("route", this.$route.query);
      this.btnActive.days = false;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      let obj = this.$route.query;

      //   for (const [key, value] of Object.entries(this.form)) {
      //   formData.append(key, value);
      // }
      for (const [key, value] of Object.entries(this.$route.query.btnActive)) {
        if (value) {
          console.log("value", value);
          if (key == "days") {
            this.btnActive.days = true;
          } else if (key == "month") {
            this.btnActive.month = true;
          } else if (key == "lastMonth") {
            this.btnActive.lastMonth = true;
          } else if (key == "period") {
            this.btnActive.period = true;
            setTimeout(() => {
              this.filter.start_date = this.$route.query.filter.start_date;
              this.filter.end_date = this.$route.query.filter.end_date;
              this.pagination();
            }, 1000);
            this.onShowPeriod = true;
          } else if (key == "year") {
            this.btnActive.year = true;
          }
          this.btnActiveOnClick(key);
        }
      }
    }
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
